import {defineStore} from 'pinia'

import {isTest} from '@/utils/isTest'

const IS_TEST = isTest()

export const useEventDataStore = defineStore({
  id: 'event-data',
  state: () => ({
    events: {},
    featuredEvents: [],
    filters: {},
  }),
  actions: {
    async getEventBySlug(slug, locale) {
      let eventKey = slug + '|' + locale
      if (this.events[eventKey]) {
        return this.events[eventKey]
      }

      const apiUrl = 'event/' + slug
      if (IS_TEST) {
        return getUnitTestDataForStore(apiUrl)
      }
      const response = await $fetch(useRuntimeConfig().public.apiBaseUrl + '/' + apiUrl, {method: 'GET'})

      const eventData = response.data.event
      this.events[eventKey] = eventData

      return eventData
    },

    async getInitialEvents() {
      const page = 1
      const upcomingEventsPageSize = useRuntimeConfig().public.upcomingEventsToShow

      if (IS_TEST) {
        const mockedEvents = getUnitTestDataForStore('events')
        console.log(mockedEvents)
        return {
          featuredEventsData: mockedEvents,
          upcomingEventsData: mockedEvents,
        }
      }

      const featuredEventsPromise = this.getFeaturedEventsPromise(page).then((response) => {
        return response.data
      })

      const upcomingEventsPromise = this.getUpcomingEventsPromise(page, upcomingEventsPageSize).then((response) => {
        return response.data
      })

      const featuredEventsData = await featuredEventsPromise
      const upcomingEventsData = await upcomingEventsPromise

      return {featuredEventsData, upcomingEventsData}
    },

    async getFeaturedEvents(page) {
      const events = await this.getFeaturedEventsPromise(page)

      return events.data
    },

    async getUpcomingEvents(page, pageSize) {
      const events = await this.getUpcomingEventsPromise(page, pageSize)

      return events
    },

    getFeaturedEventsPromise(page) {
      return $fetch(useRuntimeConfig().public.apiBaseUrl + '/events', {
        method: 'POST',
        body: {
          only_featured: true,
          paginate: true,
          page: page,
        },
      })
    },

    getUpcomingEventsPromise(page, pageSize) {
      return $fetch(useRuntimeConfig().public.apiBaseUrl + '/events', {
        method: 'POST',
        body: {
          exclude_featured: true,
          paginate: true,
          page: page,
          page_size: pageSize,
          filters: this.filters,
        },
      })
    },

    async getEventTabbedContent(eventId) {
      const apiUrl = `event/${eventId}/content-blocks/`
      if (isTest()) {
        return getUnitTestDataForStore(apiUrl)
      }
      const response = await $fetch(useRuntimeConfig().public.apiBaseUrl + '/' + apiUrl, {method: 'GET'})

      return response.data
    },

    async editTicketFields(ticketFields) {
      const response = await $fetch(useRuntimeConfig().public.apiBaseUrl + '/ticket-fields', {
        method: 'POST',
        body: ticketFields,
      })

      return response
    },

    async getEventDates(eventId) {
      let apiUrl = `event/${eventId}/dates/`
      if (isTest()) {
        return getUnitTestDataForStore(apiUrl)
      }
      const response = await $fetch(useRuntimeConfig().public.apiBaseUrl + '/' + apiUrl, {method: 'GET'})

      return response.data
    },

    async getEventSessions(eventId, dates) {
      const apiUrl = `event/${eventId}/sessions/?dates=${dates}`
      const response = await $fetch(useRuntimeConfig().public.apiBaseUrl + '/' + apiUrl, {method: 'GET'})

      return response.data
    },

    async sendTicket(data) {
      const response = await $fetch(useRuntimeConfig().public.apiBaseUrl + 'send-ticket', {
        method: 'POST',
        body: data,
      })

      return response
    },

    setFilters(type, filters) {
      if (!filters || filters.length === 0) {
        delete this.filters[type]
        return
      }

      this.filters[type] = filters
    },
  },
})
